import cryptoverseImage from '../../assets/cryptoverse.PNG';
import laisiiImage from '../../assets/personal_trainer.svg';
import robopunks from '../../assets/robopunks.PNG';
import swelegend from '../../assets/swelegend.png';

export const ProjectData = [
  {
    id: 8,
    title: 'SWE Legend',
    about: 'A Blog to help Engineers with Technical Interviews',
    tags: ['NextJS', 'GraphCMS'],
    demo: 'https://www.swelegend.com',
    image: swelegend,
  },
  {
    id: 7,
    title: 'RoboPunks',
    about:
      'An NFT Minting Site for RoboPunks. It is on the Rinkeby Test Network',
    tags: [
      'ReactJS',
      'Chakra UI',
      'Ethers',
      'Web3',
      'Rinkeby',
      'Hardhat',
      'Infura',
      'Netlify',
    ],
    demo: 'https://robopunk.netlify.app/',
    image: robopunks,
  },
  {
    id: 6,
    title: 'Laisii',
    about:
      'Sports and Fitness Marketplace where Trainers can create, list and sell their courses, and buyers can buy and learn. Buyers pay a one time fee for Fitness Courses and will have them forever in their accounts. Trainers can also create Fitness Communities that hold all of their fitness content, buyers can pay a monthly subscription to gain access a specific community.',
    tags: [
      'NextJS',
      'NodeJS',
      'Bootstrap',
      'TailwindCSS',
      'MongoDB',
      'AWS S3',
      'Stripe',
      'Digital Ocean',
      'Nginx',
    ],
    demo: 'https://Laisii.com',
    image: laisiiImage,
  },
  {
    id: 5,
    title: 'Cryptoverse',
    about:
      'Latest Crypto Info, Graphs, Exchanges, and News at a glance. Built using React and APIs such as CoinRanking and Bing News Search from RapidAPI.',
    tags: ['ReactJS', 'CoinRanking API', 'Bing News Search API'],
    demo: 'https://cryptoverse-project.netlify.app/',
    image: cryptoverseImage,
  },
  {
    id: 4,
    title: 'Super Mario Bros Clone',
    about:
      'Relive your childhood in this Super Mario Bros Clone right in your browser.',
    tags: ['ReactJS', 'KaboomJS'],
    demo: 'https://mariojs.netlify.app',
    github: 'https://github.com/randyh0120/Mariojs',
    image:
      'https://cdn02.nintendo-europe.com/media/images/10_share_images/games_15/virtual_console_nintendo_3ds_7/SI_3DSVC_SuperMarioBros_image1600w.jpg',
  },
  {
    id: 3,
    title: 'Amazon Clone',
    about:
      'A Simple Amazon Clone with a functioning Shopping Cart built in AngularJS.',
    tags: ['AngularJS'],
    demo: 'https://affectionate-lovelace-04d7b1.netlify.app/',
    github: 'https://github.com/randyh0120/Angular_Amazon_Clone',
    image:
      'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg',
  },
  {
    id: 2,
    title: 'Almuhallil - Financial Market Insights Langing Page',
    about:
      'Crucial Financial Insights at Crucial Times. From Stocks, Crpyto, and Forex we got you covered with daily reports and actionable insights sent right to your inbox. The name Almuhallil originates from the Arabic translation of the english word Analyst. This is a landing page and the application is a work in progress.',
    tags: ['HTML', 'CSS', 'JS', 'AWS'],
    demo: 'https://almuhallil.netlify.app/',
    github: 'https://github.com/randyh0120/Almuhallil_LandingPage',
    image:
      'https://www.springboard.com/library/static/c8a8b68015e12fea64edae0ab4e9dd71/1f368/shutterstock_1814645042.jpg',
  },
  {
    id: 1,
    title: 'Autonomous Car',
    about:
      'Same tech in a Tesla, but on a smaller scale. This is a autonomous arduino car that can also be controlled with an android app over bluetooth.',
    tags: ['C/C++', 'Java'],
    youtube: 'https://www.youtube.com/watch?v=pV5OJfdKUMk',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/61BZ-Aq2b7L._AC_SY450_.jpg',
  },
];
