import React from 'react';
import Separator from '../../common/separator/Separator';
import SocialContact from '../../common/social-contact/SocialContact';

import './Contact.css';

function Contact() {
  const onDownloadResumeClick = () => {
    const pdfName = 'RandyHattab_Resume.pdf';

    // using Java Script method to get PDF file
    fetch(require('../../../assets/RandyHattab_Resume.pdf').default).then(
      (response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = pdfName;
          alink.click();
        });
      }
    );
  };

  return (
    <div className="contact" id="contact">
      <Separator />
      <label className="section-title">Contact</label>
      <div className="contact-container">
        <div className="contact-left">
          <p>Want to get in touch? Contact me on any platform</p>
          <SocialContact />
        </div>
        <div className="download">
          <a
            href={require('../../../assets/RandyHattab_Resume.pdf').default}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onDownloadResumeClick}
          >
            <i className="fe-rr-cloud-download download-icon" />
            Download Resume
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
