export const SocialData = [
  {
    platform: 'Github',
    link: 'https://github.com/randyh0120',
    icon: require('../../assets/icons/github.svg').default,
  },
  {
    platform: 'Linkedin',
    link: 'https://www.linkedin.com/in/randyhattab/',
    icon: require('../../assets/icons/linkedin.svg').default,
  },
  {
    platform: 'Twitter',
    link: 'https://twitter.com/randyhattab',
    icon: require('../../assets/icons/twitter.svg').default,
  },
  {
    platform: 'Instagram',
    link: 'https://www.instagram.com/RandyHattab',
    icon: require('../../assets/icons/instagram.svg').default,
  },
  // {
  //   platform: 'YouTube',
  //   link: 'https://www.youtube.com/RandyHattab',
  //   icon: require('../../assets/icons/youtube.svg').default,
  // },
  // {
  //   platform: "Facebook",
  //   link: "www.instagram.com/RandyHattab",
  //   icon: require('../../assets/icons/facebook.svg').default
  // },
];
