// import logo from './logo.svg';
import './App.css';

import Home from './components/home/Index'

function App() {
  return (
    <Home />
  );
}

export default App;
