export const SkillsData = [
  {
    type: 'Mobile',
    list: [
      {
        name: 'Swift',
        icon: <i class="devicon-swift-plain colored"></i>,
      },
      {
        name: 'iOS',
        icon: <i class="devicon-apple-original colored"></i>,
      },
      {
        name: 'React Native',
        icon: <i class="devicon-react-original colored"></i>,
      },
    ],
  },
  {
    type: 'Frontend',
    list: [
      {
        name: 'ReactJS',
        icon: <i className="devicon-react-original colored"></i>,
      },
      {
        name: 'NextJS',
        icon: <i className="devicon-nextjs-original colored"></i>,
      },
      {
        name: 'Angular',
        icon: <i class="devicon-angularjs-plain colored"></i>,
      },
      {
        name: 'Javascript',
        icon: <i className="devicon-javascript-plain colored"></i>,
      },
      {
        name: 'Typescript',
        icon: <i className="devicon-typescript-plain colored"></i>,
      },
      {
        name: 'CSS',
        icon: <i class="devicon-css3-plain colored"></i>,
      },
      {
        name: 'Bootstrap',
        icon: <i class="devicon-bootstrap-plain colored"></i>,
      },
      {
        name: 'Tailwind CSS',
        icon: <i class="devicon-tailwindcss-plain colored"></i>,
      },
      {
        name: 'Material UI',
        icon: <i class="devicon-materialui-plain colored"></i>,
      },
      {
        name: 'Bulma',
        icon: <i class="devicon-bulma-plain colored"></i>,
      },
    ],
  },
  {
    type: 'Backend',
    list: [
      {
        name: 'NodeJS',
        icon: <i className="devicon-nodejs-plain colored"></i>,
      },
      {
        name: 'Express',
        icon: <i class="devicon-express-original colored"></i>,
      },
      {
        name: 'C#',
        icon: <i class="devicon-csharp-plain colored"></i>,
      },
      // {
      //   name: '.Net Core',
      //   icon: <i class="devicon-dotnetcore-plain colored"></i>,
      // },
      {
        name: '.Net',
        icon: <i class="devicon-dot-net-plain colored"></i>,
      },
      {
        name: 'PHP',
        icon: <i class="devicon-php-plain colored"></i>,
      },
    ],
  },
  {
    type: 'Blockchain',
    list: [
      {
        name: 'Solidity',
        icon: <i class="devicon-solidity-plain colored"></i>,
      },
    ],
  },
  {
    type: 'Databases',
    list: [
      {
        name: 'MondoDB',
        icon: <i class="devicon-mongodb-plain colored"></i>,
      },
      {
        name: 'MySQL',
        icon: <i class="devicon-mysql-plain colored"></i>,
      },
      {
        name: 'MS SQL Server',
        icon: <i class="devicon-microsoftsqlserver-plain colored"></i>,
      },
      {
        name: 'AWS',
        icon: <i class="devicon-amazonwebservices-original colored"></i>,
      },
      {
        name: 'Firebase',
        icon: <i class="devicon-firebase-plain colored"></i>,
      },
    ],
  },
  {
    type: 'Desktop',
    list: [
      {
        name: 'Electron',
        icon: <i class="devicon-electron-original colored"></i>,
      },
    ],
  },
  {
    type: 'Analytics',
    list: [
      {
        name: 'Python',
        icon: <i class="devicon-python-plain colored"></i>,
      },
      {
        name: 'Numpy',
        icon: <i class="devicon-numpy-original colored"></i>,
      },
      {
        name: 'Pandas',
        icon: <i class="devicon-pandas-original colored"></i>,
      },
      {
        name: 'Matlab',
        icon: <i class="devicon-matlab-plain colored"></i>,
      },
    ],
  },
  {
    type: 'Other Programming Languages',
    list: [
      {
        name: 'C',
        icon: <i class="devicon-c-plain colored"></i>,
      },
      {
        name: 'C++',
        icon: <i class="devicon-cplusplus-plain colored"></i>,
      },
      {
        name: 'Java',
        icon: <i class="devicon-java-plain colored"></i>,
      },
    ],
  },
  {
    type: 'IDEs',
    list: [
      {
        name: 'VS Code',
        icon: <i class="devicon-vscode-plain colored"></i>,
      },
      {
        name: 'Visual Studio',
        icon: <i class="devicon-visualstudio-plain colored"></i>,
      },
      {
        name: 'Xcode',
        icon: <i class="devicon-xcode-plain colored"></i>,
      },
      {
        name: 'PyCharm',
        icon: <i class="devicon-pycharm-plain colored"></i>,
      },
      {
        name: 'Jupyter',
        icon: <i class="devicon-jupyter-plain-wordmark colored"></i>,
      },
    ],
  },
  {
    type: 'Package Manager',
    list: [
      {
        name: 'NPM',
        icon: <i class="devicon-npm-original-wordmark colored"></i>,
      },
      {
        name: 'Yarn',
        icon: <i class="devicon-yarn-plain colored"></i>,
      },
      {
        name: 'Nuget',
        icon: <i class="devicon-nuget-original colored"></i>,
      },
    ],
  },
  {
    type: 'Other Technologies',
    list: [
      {
        name: 'Git',
        icon: <i class="devicon-git-plain colored"></i>,
      },
      {
        name: 'Gitlab',
        icon: <i class="devicon-gitlab-plain colored"></i>,
      },
      {
        name: 'Github',
        icon: <i class="devicon-github-original colored"></i>,
      },
      {
        name: 'Digital Ocean',
        icon: <i class="devicon-digitalocean-plain colored"></i>,
      },
      {
        name: 'Nginx',
        icon: <i class="devicon-nginx-original colored"></i>,
      },
      {
        name: 'Heroku',
        icon: <i class="devicon-heroku-original colored"></i>,
      },
      {
        name: 'Jenkins',
        icon: <i class="devicon-jenkins-line"></i>,
      },
      {
        name: 'RaspberryPi',
        icon: <i class="devicon-raspberrypi-line colored"></i>,
      },
      {
        name: 'Jira',
        icon: <i class="devicon-jira-plain colored"></i>,
      },
      {
        name: 'Confluence',
        icon: <i class="devicon-confluence-original colored"></i>,
      },
      {
        name: 'Adobe Xd',
        icon: <i class="devicon-xd-plain colored"></i>,
      },
      {
        name: 'Photoshop',
        icon: <i class="devicon-photoshop-plain colored"></i>,
      },
    ],
  },
];
