import React, { useState } from 'react'

import Web from './web/Web'
import Mobile from './mobile/Mobile'

import './Header.css'

function Index() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="header">
      <div className="logo">Randy Hattab</div>
      <div className="menu">
        <div className="web-menu"><Web /></div>
        <div className="mobile-menu">
          <div onClick={() => setIsOpen(!isOpen)}>
            <i className="fi-rr-menu-burger menu-icon"></i>
          </div>
          {isOpen && <Mobile isOpen={isOpen} setIsOpen={setIsOpen} />}
        </div>
      </div>
    </div>
  )
}

export default Index
