import React from 'react';

import './WorkCard.css';

function WorkCard({ item }) {
  return (
    <div>
      <div>
        <img
          src={item.companyLogo}
          alt={item.company}
          className="work-logo"
          height="100"
          style={{ paddingTop: '10px' }}
        />
      </div>

      <div
        className="work-card"
        style={{ borderBottom: '1px solid rgba(200, 200, 200)' }}
      >
        <div className="work-info">
          <label className="company-name">
            <b>{item.company}</b>
          </label>
          <label className="company-name">{item.designation}</label>
          <div className="work-dates">
            <label>{item.dateJoined}</label> - <label>{item.dateEnd}</label>
          </div>
          <div className="work-desc" id="work-desc">
            <p>{item.work}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkCard;
