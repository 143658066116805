export const WorkData = [
  {
    company: 'Aircraft Technical Publishers',
    designation: 'Software Engineer',
    dateJoined: 'March 2021',
    dateEnd: 'Current',
    companyLogo:
      'https://media-exp1.licdn.com/dms/image/C4D0BAQGm5OXiBQyplQ/company-logo_200_200/0/1625754348362?e=2159024400&v=beta&t=2y9BHlpDImrpqrixGuLXTTVLRtpzgynhnqA4STTu3PA',
    work: (
      <ul>
        <li>
          Led the design and development of multiple enterprise-level
          microservice applications driving $65 million of revenue every year
          using the latest technologies of AWS, C#, .NET, SQL, NoSQL, Node,
          React, Angular, & Swift
        </li>
        <li>
          Designed and implemented scalable APIs for managing first and
          third-party proprietary licenses using .NET, Nodejs, and AWS that
          process billions of requests per year.
        </li>
        <li>
          Led the development of several products E2E from identifying system
          requirements and partner dependencies to workload balancing, software
          implementation, engineering, testing, and configuring metrics, alarms,
          monitors, and dashboard.
        </li>
        <li>
          Implemented features for cost and equipment tracking that manages
          $174.8 billion in transactions per year.
        </li>
        <li>
          Continuous Integration/Deployment, Pipeline Integration, pull
          requests, code reviews, and mentoring.
        </li>
      </ul>
    ),
  },
  {
    company: 'Florida Gulf Coast University',
    designation: 'Software Engineer',
    dateJoined: 'April 2021',
    dateEnd: 'Current',
    companyLogo:
      'https://upload.wikimedia.org/wikipedia/en/thumb/9/95/Florida_Gulf_Coast_Eagles_logo.svg/1200px-Florida_Gulf_Coast_Eagles_logo.svg.png',
    work: (
      <ul>
        <li>
          Designed and implemented groundbreaking analytics tool that finds
          trends in real estate market using python, node, and react.
        </li>
        <li>
          Led discovery and implementation of algorithms to understand,
          accurately describe, and project the real estate market.
        </li>
      </ul>
    ),
  },
  {
    company: 'Lee Health',
    designation: 'Software Engineer',
    dateJoined: 'March 2020',
    dateEnd: 'March 2021',
    companyLogo:
      'https://www.leehealth.org/getmedia/a3cc3636-0562-43b8-984a-7a9380bc6a4d/lee_health_logo_master.png?width=540&height=172&ext=.png',
    work: (
      <ul>
        <li>
          Designed and implemented enterprise healthcare applications using
          Python and NodeJS.
        </li>
        <li>
          Reported directly to VP of Analytics to implement, manage, and own
          Python server for analytics which led to a 1,700% increase in runtime
          for machine learning projections and dashboards.
        </li>
        <li>
          Designed and implemented dashboards for COVID-19 vaccinations on the
          state and federal level driving an increase of productivity (4hr to
          4sec) and $400 thousand in yearly savings.
        </li>
        <li>
          Developed automation that tracked employee responses to time sensitive
          visits (heart attacks) saving lives.
        </li>
        <li>
          Led and developed an internal tool to track Doctors prescribing blood
          to patients leading to better care.
        </li>
        <li>
          Increased Scalability, Optimized Data Delivery, Cost Reduction,
          Improved and automated processes and led overall improvements to
          technology infrastructure while working with Directors and Executives.
        </li>
      </ul>
    ),
  },
  {
    company: 'FGCU',
    designation: 'Bachelors of Science, Software Engineering',
    dateJoined: 'Aug 2015',
    dateEnd: 'May 2020',
    companyLogo:
      'https://upload.wikimedia.org/wikipedia/en/thumb/9/95/Florida_Gulf_Coast_Eagles_logo.svg/1200px-Florida_Gulf_Coast_Eagles_logo.svg.png',
    work: (
      <ul>
        <li>
          Graduated from FGCU with a Bachelors of Science in Software
          Enginneering.
        </li>
        <li>
          <b>Undergraduate Coursework:</b> Operating Systems; Databases;
          Software Foundations; Robotics; Algorithms; Programming Languages;
          Soft. Architecture & Design; Engineering Entrepreneurship; Calculus
          III.
        </li>
        <li>Majored in Business Management for the first 2 years.</li>
      </ul>
    ),
  },
  {
    company: 'FGCU',
    designation: 'MBA, Leadership/Management',
    dateJoined: 'May 2023',
    dateEnd: 'Dec 2024',
    companyLogo:
      'https://upload.wikimedia.org/wikipedia/en/thumb/9/95/Florida_Gulf_Coast_Eagles_logo.svg/1200px-Florida_Gulf_Coast_Eagles_logo.svg.png',
    work: (
      <ul>
        <li>
          Working towards an MBA so to achieve my Career Goals of becoming an
          Executive in the software industry.
        </li>
      </ul>
    ),
  },
];
