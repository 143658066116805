import React from 'react';

import Header from '../header/Index';
import Body from '../body/Body';
import Footer from '../footer/Footer';

import './Home.css';

function Index() {
  return (
    <div className="home">
      <div>
        <Header />
      </div>

      <div>
        <Body />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
