import React from 'react';

import SocialContact from '../../common/social-contact/SocialContact';

import './About.css';

function About() {
  return (
    <div className="about">
      <div className="about-top">
        <div className="about-info">
          Hello There 👋, I'm
          <br />
          <span className="info-name">Randy Hattab</span>.
          <br /> I love building Websites and Mobile Apps.
        </div>
        <div className="about-photo">
          <img
            src={require('../../../assets/coding.svg').default}
            alt="Coding"
            className="picture"
          />
        </div>
      </div>

      <SocialContact />
    </div>
  );
}

export default About;
