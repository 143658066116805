import React from 'react';

import Separator from '../../common/separator/Separator';
import { WorkData } from '../../data/Work';

import './Work.css';
import WorkCard from './WorkCard';

function Work() {
  const data = WorkData;

  return (
    <div className="work" id="work">
      <Separator />

      <label className="section-title">Work</label>
      <div className="work-list">
        {data.map((item, index) => {
          return <WorkCard key={index} item={item} />;
        })}
      </div>
    </div>
  );
}

export default Work;
