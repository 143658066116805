import React from 'react'
import { SocialData } from '../../data/Social'

import './SocialContact.css'

function SocialContact() {
  const data = SocialData

  return (
    <div className="social-contact">
      {data.map((item, index) => {
        return (
          <a key={index} href={item.link}>
            <div className="social-icon-div">
              <img src={item.icon} alt={item.platform} className="social-icon" />
            </div>
          </a>
        )
      })}
    </div>
  )
}

export default SocialContact
