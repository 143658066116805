import React from 'react';

import './ProjectCard.css';

function ProjectCard({ project }) {
  return (
    <div
      className="project-card"
      style={{ borderBottom: '1px solid rgba(200, 200, 200)' }}
    >
      <div className="project-info">
        <label className="project-title">{project.title}</label>
        <div className="project-links">
          {project.demo && (
            <a
              className="project-link"
              href={project.demo}
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="link-button">
                <i className="fi-rr-globe link-space-right"></i>Demo
              </div>
            </a>
          )}
          {project.github && (
            <a
              className="project-link"
              href={project.github}
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="link-button">
                <i className="devicon-github-original colored link-space-right"></i>
                Github
              </div>
            </a>
          )}
          {project.youtube && (
            <a
              className="project-link"
              href={project.youtube}
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="link-button">
                <i className="fa fa-youtube-play link-space-right"></i>
                Youtube
              </div>
            </a>
          )}
        </div>
        <p>{project.about}</p>
        <div className="project-tags">
          {project.tags.map((tag, index) => {
            return (
              <label key={index} className="tag">
                {tag}
              </label>
            );
          })}
        </div>
      </div>
      <img src={project.image} alt={project.title} className="project-photo" />
    </div>
  );
}

export default ProjectCard;
