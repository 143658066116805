import React from 'react';

import { ProjectData } from '../../data/Projects';

import ProjectCard from './ProjectCard';
import Separator from '../../common/separator/Separator';

import './Projects.css';

function Projects() {
  const data = ProjectData;
  return (
    <div className="projects" id="projects">
      <Separator />
      <label className="section-title">Projects</label>
      <div>
        {data.map((project, index) => {
          return <ProjectCard key={index} project={project} />;
        })}
      </div>
    </div>
  );
}

export default Projects;
