import React from 'react';
import Separator from '../../common/separator/Separator';
import { SkillsData } from '../../data/Skills';

import './Skills.css';
import SkillsCard from './SkillsCard';

function Skills() {
  const data = SkillsData;

  return (
    <div className="skills" id="skills">
      <Separator />
      <label className="section-title">Skills</label>
      <div className="skills-container">
        {data.map((item, index) => {
          return (
            <div className="skills-section" key={index}>
              <label className="skills-section-title">{item.type}</label>
              <div className="skills-list">
                {item.list.map((skill, index) => {
                  return <SkillsCard key={index} skill={skill} />;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Skills;
